import { IProcessedData, ServerResponseMessagesType } from '../types/ApiResponse';
import { IPadCompletionData } from '../types/Pad';
import { AccuCode } from '../constants/Pad';
import { getPadErrorMessages } from '../utils/Pad';

export const getAccuData = (
    accuCode: string | null,
    ebtTransactionId: string | null,
    padTrigger: string | null,
): IProcessedData<IPadCompletionData> => {
    const padCompletionResponseMessages: ServerResponseMessagesType = getPadErrorMessages(padTrigger);

    if (ebtTransactionId && accuCode === AccuCode.accu000) {
        return {
            statusMessage: padCompletionResponseMessages[accuCode],
            data: {
                ebtTransactionId,
            },
        };
    }

    if (accuCode && accuCode in padCompletionResponseMessages) {
        throw new Error(padCompletionResponseMessages[accuCode]);
    }

    throw new Error(padCompletionResponseMessages[AccuCode.notSupported]);
};
