import { getQueryParams } from './QueryString';

export enum RequestMethod {
  Get = 'GET',
  Post = 'POST',
}

export interface IResponse<T> {
  status: number;
  data: T;
}

interface RequestBody {
  [key: string]: unknown;
}

const baseEbtServiceUrl: string = process.env.REACT_APP_EBT_API || '';

export const sendRequest = <T>(
  url: string,
  method = RequestMethod.Get,
  body?: RequestBody,
  refreshedAccessToken?: string,
): Promise<IResponse<T>> => {
  const accessToken: string | null = refreshedAccessToken || getAccessTokenFromQueryParams();

  if (!accessToken) {
    return Promise.reject({
      data: 'Bad access token!',
    });
  }

  return fetch(`${baseEbtServiceUrl}${url}`, {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  }).then(res => handleResponse<T>(res));
};

const handleResponse = <T>(response: Response): Promise<IResponse<T>> => {
  return response.json().then(data => {
    const handledResponse = {
      status: response.status,
      data: data,
    };

    if (response.ok) {
      return handledResponse;
    } else {
      throw handledResponse;
    }
  });
};

const getAccessTokenFromQueryParams = (): string | null => {
  const tokenQueryParamName = 'accessToken';
  const accessToken: string | null = getQueryParams().get(tokenQueryParamName);

  if (!accessToken) {
    return null;
  }

  return accessToken;
};
