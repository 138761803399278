import { ServerResponse } from './ApiResponse';
import { ServerResponseMessagesType } from '../types/ApiResponse';

export const PROCESS_EBT_CARD_ERROR = 'We are unable to process your EBT Card at this time. Please try again later.';

export const PadCreationResponseMessages: ServerResponseMessagesType = {
    [ServerResponse.notSupported]: PROCESS_EBT_CARD_ERROR,
    [ServerResponse.success]: '',
};

export const PIN_PAD_LANGUAGE = 'en-US';

export enum AccuCode {
    notSupported = -1,
    accu000 = 'ACCU000',
    accu200 = 'ACCU200',
    accu400 = 'ACCU400',
    accu600 = 'ACCU600',
    accu710 = 'ACCU710',
    accu720 = 'ACCU720',
    accu730 = 'ACCU730',
    accu800 = 'ACCU800',
}

export enum PadTrigger {
    Payment = 'payment',
    Balance = 'balance',
}

export const PaymentPadCompletionResponseMessages: ServerResponseMessagesType = {
    [AccuCode.notSupported]: 'EBT Payment was not successful. Please try again.',
    [AccuCode.accu000]: '',
    [AccuCode.accu200]: 'EBT Payment was terminated. Please try again.',
    [AccuCode.accu400]: 'EBT Payment was terminated. Please try again.',
    [AccuCode.accu600]: 'EBT Payment was not successful. Please try again.',
    [AccuCode.accu710]:
        'We are unable to process your EBT Card at this time. Please try a different method of payment.',
    [AccuCode.accu720]: 'EBT Payment was not successful. Please try again.',
    [AccuCode.accu730]: 'EBT Payment was not successful. Please try again.',
    [AccuCode.accu800]: 'EBT Payment was not successful. Please try again.',
};

export const BalancePadCompletionResponseMessages: ServerResponseMessagesType = {
    [AccuCode.notSupported]: 'EBT Check Balance was not successful. Please try again.',
    [AccuCode.accu000]: '',
    [AccuCode.accu200]: 'EBT Check Balance was terminated. Please try again.',
    [AccuCode.accu400]: 'EBT Check Balance was terminated. Please try again.',
    [AccuCode.accu600]: 'EBT Check Balance was not successful. Please try again.',
    [AccuCode.accu710]:
        'We are unable to process your EBT Card at this time. Please try a different method of payment.',
    [AccuCode.accu720]: 'EBT Check Balance was not successful. Please try again.',
    [AccuCode.accu730]: 'EBT Check Balance was not successful. Please try again.',
    [AccuCode.accu800]: 'EBT Check Balance was not successful. Please try again.',
};
