import { getQueryParams } from './QueryString';
import { isValidUrl } from './Url';

export const getHostNameFromQueryParams = (): string => {
    const hostQueryParamName = 'host';
    const hostName: string | null = getQueryParams().get(hostQueryParamName);

    if (!hostName || !isValidUrl(hostName)) {
        return '';
    }

    return hostName;
};
