import {
    BalancePadCompletionResponseMessages,
    PaymentPadCompletionResponseMessages,
    PadTrigger,
} from '../constants/Pad';
import { ServerResponseMessagesType } from '../types/ApiResponse';

export const getPadErrorMessages = (padType: string | null): ServerResponseMessagesType => {
    return padType === PadTrigger.Balance ? BalancePadCompletionResponseMessages : PaymentPadCompletionResponseMessages;
};
