import React from 'react';
import { sendPostMessage } from '../../utils/PostMessage';
import { PostMessageEvent } from '../../constants/PostMessages';
import { getAccuData } from '../../services/PaySecure';
import { getQueryParams } from '../../utils/QueryString';
import { Loader } from 'frontend-ui-kit';

interface IPinPadCompletionProps {
}

interface IPinPadCompletionState {
}

const PadCompletionQueryParams = {
  accuCode: 'AccuResponseCode',
  transactionId: 'PCITransactionId',
  mercatoBaseUrl: 'MercatoBaseUrl',
  padTrigger: 'PadTrigger',
};

class PadCompletion extends React.PureComponent<IPinPadCompletionProps, IPinPadCompletionState> {
  componentDidMount(): void {
    this.handlePadCompletionResponse();
  }

  handlePadCompletionResponse = (): void => {
    const accuCode: string | null = getQueryParams().get(PadCompletionQueryParams.accuCode);
    const ebtTransactionId: string | null = getQueryParams().get(PadCompletionQueryParams.transactionId);
    const mercatoBaseUrl: string | null = getQueryParams().get(PadCompletionQueryParams.mercatoBaseUrl);
    const padTrigger: string | null = getQueryParams().get(PadCompletionQueryParams.padTrigger);

    if (mercatoBaseUrl) {
      try {
        const accuData = getAccuData(accuCode, ebtTransactionId, padTrigger);

        sendPostMessage(
          PostMessageEvent.PinPadAuthorized,
          {
            success: true,
            data: accuData.data,
          },
          mercatoBaseUrl,
        );
      } catch ({ message }) {
        sendPostMessage(
          PostMessageEvent.PinPadAuthorized,
          {
            success: false,
            data: {
              errorMessage: message as string,
            },
          },
          mercatoBaseUrl,
        );
      }
    }
  };

  render(): JSX.Element {
    return <Loader show={true} />;
  }
}

export default PadCompletion;
