import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import ROUTES from '../../data/routes';
import CardCreation from '../card-creation/CardCreation';
import PadCreation from '../pin-pad/PadCreation';
import PadCompletion from '../pin-pad/PadCompletion';

const PciRouter = (): JSX.Element => {
    return (
        <Router>
            <Switch>
                <Route
                  exact
                  path={ROUTES.CREATE_CARD}
                  component={CardCreation}
                />
                <Route
                  exact
                  path={ROUTES.CREATE_PIN_PAD}
                  component={PadCreation}
                />
                <Route
                  exact
                  path={ROUTES.COMPLETE_PIN_PAD}
                  component={PadCompletion}
                />
                <Redirect to={ROUTES.CREATE_CARD} />
            </Switch>
        </Router>
    );
};

export default PciRouter;
