import { IEbtServiceError, IProcessedData } from '../types/ApiResponse';
import { IResponse, RequestMethod, sendRequest } from '../utils/Request';
import { IPadCreationData, IPadCreationDTO } from '../types/Pad';
import { PadCreationResponseMessages } from '../constants/Pad';
import { ServerResponse } from '../constants/ApiResponse';

type CreatePadData = IPadCreationData | IEbtServiceError;

export const createPad = (cardId: string, storeId: string): Promise<IProcessedData<IPadCreationDTO>> => {
    return sendRequest<CreatePadData>('/create-pad', RequestMethod.Post, {
        card: {
            id: cardId,
        },
        merchant: {
            id: storeId,
        },
    })
        .then(res => createResponseData(res))
        .catch(() => {
            throw new Error(createErrorMessage());
        });
};

const createResponseData = (response: IResponse<CreatePadData>): IProcessedData<IPadCreationDTO> => {
    const data: IPadCreationData = response.data as IPadCreationData;

    return {
        statusMessage: PadCreationResponseMessages[response.status],
        data: {
            sessionId: data.transaction.pad.session,
            transactionId: data.transaction.id,
        },
    };
};

const createErrorMessage = (): string => {
    return PadCreationResponseMessages[ServerResponse.notSupported];
};
