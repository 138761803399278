import React from 'react';
import { Input, Button, SaveButton, Errors } from 'frontend-ui-kit';

import { REGEX_NOT_DIGIT } from '../../utils/RegExp';
import { createCard } from '../../services';
import { PostMessageEvent } from '../../constants/PostMessages';
import { sendPostMessage } from '../../utils/PostMessage';
import { IProcessedData } from '../../types/ApiResponse';
import { ICardCreationData } from '../../types/CardCreation';
import { maskCardNumber } from '../../utils/MaskCardNumber';

import styles from '../../App.module.scss';

interface ICardCreationProps {
}

interface ICardCreationState {
  cardNumber: string;
  errorMessage: string | null;
  isCardNumberValid: boolean;
  isLoading: boolean;
}

class CardCreation extends React.PureComponent<ICardCreationProps, ICardCreationState> {
  constructor(props: ICardCreationProps) {
    super(props);
    this.state = {
      cardNumber: '',
      errorMessage: null,
      isCardNumberValid: false,
      isLoading: false,
    };
  }

  componentDidMount(): void {
    window.addEventListener('message', ({ data }) => {
      if (data.event === PostMessageEvent.AccessTokenReceived) {
        this.onCreateCard(data.accessToken);
      }
    });
  }

  onChange = (value: string): void => {
    const cardNumber = value.replace(REGEX_NOT_DIGIT, '');
    this.setState({ cardNumber }, () => this.validateCardNumber());
  };

  validateCardNumber = (): void => {
    if (!this.minLengthValid()) {
      this.setState({
        errorMessage: 'SNAP EBT Card Number must be at least 16 digits long.',
        isCardNumberValid: false,
      });
      sendPostMessage(PostMessageEvent.CardCreationError, true);
    } else {
      this.setState({
        errorMessage: null,
        isCardNumberValid: true,
      });
      sendPostMessage(PostMessageEvent.CardCreationError, false);
    }
  };

  saveCard = (): void => {
    this.validateCardNumber();
    if (this.state.isCardNumberValid) {
      this.setState({ isLoading: true });
      sendPostMessage(PostMessageEvent.SaveCard);
    }
  };

  async onCreateCard(accessToken?: string): Promise<void> {
    try {
      const serverResponse = await createCard(this.state.cardNumber, accessToken);
      this.sendCreationSuccessPostMessage(serverResponse);
    } catch ({ message }) {
      this.setState({
        errorMessage: message as string,
        isLoading: false,
      });
    }
  }

  sendCreationSuccessPostMessage = (serverResponse: IProcessedData<ICardCreationData>): void => {
    sendPostMessage(PostMessageEvent.CardCreated, {
      cardNumber: maskCardNumber(this.state.cardNumber),
      cardToken: serverResponse.data.id,
    });
  };

  minLengthValid = (): boolean => this.state.cardNumber.length >= 16;

  onCancel = (): void => {
    sendPostMessage(PostMessageEvent.CancelCardCreation);
  };

  render(): JSX.Element {
    const { cardNumber, errorMessage, isCardNumberValid, isLoading } = this.state;

    return (
      <div>
        <Input
          label='EBT SNAP card number'
          maxLength={19}
          value={cardNumber}
          isRequired={true}
          onChange={(value: string) => this.onChange(value)}
          inputId='ebtCard'
        />
        <br />
        {errorMessage && <Errors errors={[errorMessage]} />}
        <br />
        <div className={styles.cardCreation_buttons}>
          <Button
            secondary={true}
            displayType='link'
            onClick={() => this.onCancel()}
          >
            Cancel
          </Button>
          <SaveButton
            icon={true}
            isEnabled={isCardNumberValid}
            isLoading={isLoading}
            onClick={this.saveCard}
          >
            Save
          </SaveButton>
        </div>
      </div>
    );
  }
}

export default CardCreation;
