import React, { RefObject } from 'react';
import { Loader } from 'frontend-ui-kit';

import { createPad } from '../../services';
import ROUTES from '../../data/routes';
import { getQueryParams, ParsedQuery } from '../../utils/QueryString';
import { sendPostMessage } from '../../utils/PostMessage';
import { PostMessageEvent } from '../../constants/PostMessages';
import { PIN_PAD_LANGUAGE, PROCESS_EBT_CARD_ERROR } from '../../constants/Pad';
import { RequestMethod } from '../../utils/Request';
import { getHostNameFromQueryParams } from '../../utils/HostName';

interface IPinPadCreationProps {
}

interface IPinPadCreationState {
  accuId: string;
  transactionId: string;
  trigger: string | null;
}

const AcculynkFormDataIds = {
  accuLanguage: 'accuLanguage',
  accuReturnUrl: 'accuReturnUrl',
  accuId: 'accuId',
  transactionId: 'transactionId',
  pciBaseUrl: 'pciBaseUrl',
  mercatoBaseUrl: 'mercatoBaseUrl',
  padTrigger: 'padTrigger',
};

const PadCreationQueryParams = {
  cardId: 'cardId',
  merchantId: 'merchantId',
  trigger: 'trigger',
};

class PadCreation extends React.PureComponent<IPinPadCreationProps, IPinPadCreationState> {
  private readonly formRef: RefObject<HTMLFormElement>;

  constructor(props: IPinPadCreationProps) {
    super(props);
    this.formRef = React.createRef<HTMLFormElement>();
    this.state = {
      accuId: '',
      transactionId: '',
      trigger: null,
    };
  }

  componentDidMount(): void {
    this.triggerPinPadCreation();
  }

  componentDidUpdate(): void {
    this.tryToSubmitForm();
  }

  triggerPinPadCreation = (): void => {
    const queryParamsObject: ParsedQuery = getQueryParams();
    const cardId: string | null = queryParamsObject.get(PadCreationQueryParams.cardId);
    const merchantId: string | null = queryParamsObject.get(PadCreationQueryParams.merchantId);

    if (!cardId || !merchantId) {
      sendPostMessage(PostMessageEvent.PinPadAuthorized, {
        success: false,
        data: {
          errorMessage: PROCESS_EBT_CARD_ERROR,
        },
      });

      return;
    }

    this.setSessionId(cardId, merchantId);
  };

  setSessionId = async (cardId: string, storeId: string): Promise<void> => {
    try {
      const serverResponse = await createPad(cardId, storeId);
      const trigger: string | null = getQueryParams().get(PadCreationQueryParams.trigger);

      this.setState({
        accuId: serverResponse.data.sessionId,
        transactionId: serverResponse.data.transactionId,
        trigger,
      });
    } catch ({ message }) {
      sendPostMessage(PostMessageEvent.PinPadAuthorized, {
        success: false,
        data: {
          errorMessage: message as string,
        },
      });
    }
  };

  getReturnUrl = (): string => {
    return `${window.location.origin}${ROUTES.COMPLETE_PIN_PAD_REDIRECT_AWS}`;
  };

  getPCIBaseUrl = (): string => {
    return `${window.location.origin}${ROUTES.COMPLETE_PIN_PAD}`;
  };

  getMercatoBaseUrl = (): string => {
    return getHostNameFromQueryParams();
  };

  tryToSubmitForm = (): void => {
    if (this.formRef.current && this.formValuesExist()) {
      this.formRef.current.submit();
    }
  };

  formValuesExist = (): boolean => {
    return Object.values(AcculynkFormDataIds).every(
      (id: string) => (document.getElementById(id) as HTMLInputElement)?.value,
    );
  };

  render(): JSX.Element {
    const paySecureUrl: string = process.env.REACT_APP_PAYSECURE_API_PIN_PAD || '';

    if (!paySecureUrl) {
      return <span>PaySecure url not found!</span>;
    }

    return (
      <div>
        <Loader show={true} />
        <form
          action={paySecureUrl}
          method={RequestMethod.Post}
          ref={this.formRef}
          hidden
        >
          <input
            id={AcculynkFormDataIds.accuLanguage}
            name='AccuLanguage'
            value={PIN_PAD_LANGUAGE}
            readOnly
          />
          <input
            id={AcculynkFormDataIds.accuReturnUrl}
            name='AccuReturnURL'
            value={this.getReturnUrl()}
            readOnly
          />
          <input
            id={AcculynkFormDataIds.accuId}
            name='AccuId'
            value={this.state.accuId}
            readOnly
          />
          <input
            id={AcculynkFormDataIds.transactionId}
            name='PCITransactionId'
            value={this.state.transactionId}
            readOnly
          />
          <input
            id={AcculynkFormDataIds.pciBaseUrl}
            name='PCIBaseUrl'
            value={this.getPCIBaseUrl()}
            readOnly
          />
          <input
            id={AcculynkFormDataIds.mercatoBaseUrl}
            name='MercatoBaseUrl'
            value={this.getMercatoBaseUrl()}
            readOnly
          />
          {this.state.trigger && (
            <input
              id={AcculynkFormDataIds.padTrigger}
              name='PadTrigger'
              value={this.state.trigger}
              readOnly
            />
          )}
        </form>
      </div>
    );
  }
}

export default PadCreation;
