import { ICardCreationData } from '../types/CardCreation';
import { IEbtServiceError, IProcessedData } from '../types/ApiResponse';
import { IResponse, RequestMethod, sendRequest } from '../utils/Request';
import { CardCreationResponseMessages } from '../constants/CardCreation';
import { ServerResponse } from '../constants/ApiResponse';

type CreateCardData = ICardCreationData | IEbtServiceError;

export const createCard = (number: string, accessToken?: string): Promise<IProcessedData<ICardCreationData>> => {
    return sendRequest<CreateCardData>(
        '/create-card',
        RequestMethod.Post,
        {
            card: {
                data: { number },
            },
        },
        accessToken,
    )
        .then(res => createResponseData(res))
        .catch(error => {
            throw new Error(createErrorMessage(error));
        });
};

const createResponseData = (response: IResponse<CreateCardData>): IProcessedData<ICardCreationData> => {
    return {
        statusMessage: CardCreationResponseMessages[response.status],
        data: response.data as ICardCreationData,
    };
};

const createErrorMessage = (response: IResponse<CreateCardData>): string => {
    if (!response?.data?.hasOwnProperty('id') && CardCreationResponseMessages[response.status]) {
        return CardCreationResponseMessages[response.status];
    }

    return CardCreationResponseMessages[ServerResponse.notSupported];
};
